@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  .shine-effect {
    position: relative;
    overflow:hidden;
  }

  .shine-effect::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    background: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0.1) 100%
    );
    transform: translateX(-100%);
    animation: shine 2s linear infinite;
  }
}
main {
  min-height: 80vh;
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.product-title   {
  text-decoration: none; /* Remove underscores */
  color: forestgreen; /* Set text color to forest green */
  text-align: center; /* Center-align text */
  height: 2.5em; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; /* Prevent wrapping */
}
.clip-ribbon {
  clip-path: polygon(100% 0%, 100% 100%, 50% 80%, 0 100%, 0 0);
}




table td,
table th {
  text-align: center;
}

.review {
  margin-top: 30px;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

.review button {
  margin-top: 10px;
}

.carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}




@import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@100..800&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
:root {
  --default-font: 'Nunito Sans', sans-serif;
  --telugu-font: 'Anek Telugu', sans-serif;
}

body {
  font-family: var(--default-font);
}

.font-anek-telugu {
  font-family: 'Anek Telugu', sans-serif;
}

.font-nunito-sans {
  font-family: 'Nunito Sans', sans-serif;
}